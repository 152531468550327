@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* styles.css o tu archivo CSS global */
.shadow-light {
  box-shadow: 0 4px 6px rgba(255, 255, 255, 0.2); /* Sombra blanca */
}

.shadow-dark {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Sombra estándar */
}
