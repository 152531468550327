@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  font-family: 'Product Sans', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@keyframes colorChange {
  0% { color: #FF0000; } 
  16% { color: #FF7F00; } 
  33% { color: #FFFF00; }
  50% { color: #7FFF00; } 
  66% { color: #00FFFF; } 
  83% { color: #0000FF; } 
  100% { color: #FF00FF; } 
}

.hover-color-change:hover {
  animation: colorChange 1.5s infinite; 
}

.always-hovered {
  animation: colorChange 1.5s infinite;
}

.always-hovered .hover-color-change {
  animation: colorChange 1.5s infinite;
}